import React from 'react';
import { Container, Row, Col, Breadcrumb} from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Helmet} from "react-helmet";
import {Link} from 'gatsby'
import PageWrapper from "../components/PageWrapper";
import { Post } from "../components/Core";
import DataTable from '../components/data/banque/banque'
import DataTableMobile from '../components/data/banque/banqueMobile'
import Disclosure from '../components/Disclosure'
import {StaticImage} from 'gatsby-plugin-image';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartBar, faGift, faCreditCard} from '@fortawesome/free-solid-svg-icons'
import { faCreditCard as faCreditCardNoire} from '@fortawesome/free-regular-svg-icons'

function Accounting(props) {

	var d = new Date();
var month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
var n = month[d.getMonth()];
var y = d.getFullYear();

    return (
        <PageWrapper footerDark>
          <Helmet>
                <meta charSet="utf-8" />
                <title>Carte bancaire - Trouvez la meilleure carte bancaire</title>
                <meta name="description" content="Trouvez votre carte bancaire avec une comparaison des prix, les offres de cartes gratuites et le classement des cartes classic, gold, premier et infinite." />
            </Helmet>
          <Header/>
          <Container fluid style={{background: 'white', marginTop: 68, paddingTop: 20, paddingBottom: 130}}>
          <Container style={{maxWidth: 1280, marginTop: 28}}>
         
          <Breadcrumb>
            <Breadcrumb.Item> <Link to={`/`} style={{color: 'rgb(41, 49, 61)', fontWeight: 300}}>Accueil</Link></Breadcrumb.Item>
            <Breadcrumb.Item active style={{ color: 'rgb(189 190 191)', fontWeight: 300}}>Carte bancaire</Breadcrumb.Item>
          </Breadcrumb>
  

          <h1 id='compareh1' style={{color: 'rgb(41, 49, 61)'}}><span>Carte Bancaire</span> - Classement 2021</h1>
          <h2 id='compareh2' style={{color: 'rgb(41, 49, 61)'}}>Trouvez la meilleure carte bancaire avec un comparatif tarifs et offres promos (mise à jour {n} {y})</h2>
          <div id='onetwothreemobile'>
          <p id='onep' style={{color: 'rgb(41, 49, 61)'}}><span id='one' style={{background: 'rgb(229 34 79)', paddingLeft: 10, paddingRight: 10, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>1</span> - Utilisez le menu de notre <span style={{fontWeight: 700}}>comparateur carte bancaire</span> pour déterminer vos priorités : promo, carte gratuite, frais</p>
          <p id='twop' style={{color: 'rgb(41, 49, 61)'}}><span id='two' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>2</span> - Comparez les offres par tarif de carte bancaire, et les offres promos disponible dès aujourd’hui</p>
          <p id='threep' style={{color: 'rgb(41, 49, 61)'}}><span id='three' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>3</span> - Sélectionnez la <span style={{fontWeight: 700}}>meilleure bancaire</span>, celle qui correspond le plus à vos besoins.</p>
          </div>

          <Disclosure/>
          <Row style={{height: 70, marginTop: -7, marginBottom: -50}}>
          
          </Row>
          </Container>
          </Container>  


     
          <Container style={{maxWidth: 1280, marginTop: -130}}>
        
          <Tabs style={{maxWidth: 1234}}>
    <TabList style={{marginLeft: 5}}>
      <Tab style={{marginLeft: 0}}><FontAwesomeIcon icon={faChartBar} style={{marginTop: 0, marginBottom: 0, fontSize: 20, marginLeft: 5, marginRight: 10}}/> Classement Complet </Tab>
      <Tab style={{marginLeft: 0}}><FontAwesomeIcon icon={faGift} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> Cartes Gratuites</Tab>
      <Tab style={{marginLeft: 0}}><FontAwesomeIcon icon={faCreditCardNoire} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> Cartes Premier / Gold</Tab>
      <Tab style={{marginLeft: 0}}><FontAwesomeIcon icon={faCreditCard} style={{marginTop: 0, marginBottom: -3, fontSize: 21, marginLeft: 5, marginRight: 10}}/> Cartes Noires</Tab>
    </TabList>

    <TabPanel>
    <DataTable row={[1,2,4,5,9,10,11,13, 14,15,16, 17,18,19,20,21,22,23,25,28,29,31,32,35,36,37]} what='cartebancaire' id='wideTable' classement='TarifCarte' order={false}/>
    <DataTableMobile row={[1,2,4,5,9,10,11,13, 14,15,16, 17,18,19,20,21,22,23,25,28,29,31,32,35,36,37]} what='cartebancaire' id='mobileTable' classement='TarifCarte' order={false}/>
    </TabPanel>
      <TabPanel>      
      <DataTable row={[12,4,18,9,19,30,20]} what='cartesgratuites' id='wideTable' classement='TarifCarte' order={false}/>
      <DataTableMobile row={[12,4,18,9,19,30,20]} what='cartesgratuites' id='mobileTable' classement='TarifCarte' order={false}/>
    </TabPanel>
      <TabPanel>    
	  <DataTable row={[12,4,18,9,19,30,20]} what='cartesbancairesgold' id='wideTable' classement='TarifCarte' order={false}/>
      <DataTableMobile row={[12,4,18,9,19,30,20]} what='cartesbancairesgold' id='mobileTable' classement='TarifCarte' order={false}/>
    </TabPanel>
    <TabPanel>
    <DataTable row={[12,4,18,9,19,30,20]} what='cartesbancairesnoires' id='wideTable' classement='TarifCarte' order={false}/>
      <DataTableMobile row={[12,4,18,9,19,30,20]} what='cartesbancairesnoires' id='mobileTable' classement='TarifCarte' order={false}/>
    </TabPanel>

  </Tabs>
            <Row className="justify-content-center">
              <Col>
            
              {/* <Disclosure/> */}
            

       
              </Col>
            </Row>
             
          </Container>
          <Container style={{marginTop: 100, marginBottom : 100}}>
          <Post>
    
      
          <h2>Comment trouver la meilleure carte bancaire ?</h2>

<StaticImage
		  	src='../images/banque-en-ligne.png' width={1200}
			alt='meilleure banque en ligne'
			placeholder='tracedSVG'
			/>


<h3 style={{marginTop: 50}}>Je veux une carte bancaire gratuite !</h3>
<p>
La carte bancaire gratuite semble être l’offre promo de choix pour la plupart des banques en ligne et néobanques. On la voit partout. Le gratuit, ça attire l’oeil ! Et la carte bancaire gratuite est devenu un des premiers critères de sélection lors d’une ouverture d’un compte en banque.
</p><p>
Mais est-elle réellement gratuite ? Quelle est la différence entre les cartes bancaires ? La carte bancaire gratuite est-elle exactement la même que chez un établissement payant ?
</p><p>
Il existe 3 types de cartes bancaires :
</p>
<h4>
Virtuelle, la carte sans carte</h4>
<p>
Une carte bancaire virtuelle, est une carte sans carte ! 😉 C’est les chiffres associés à une carte sans le rectangle plastique (8.5cm x 5.4cm) que l’on peut toucher :
</p>
<ul>
  <li>Numéro à 16 chiffres sur le devant</li>
  <li>Date de validité</li>
  <li>Le code CVC à l’arrière de la carte.</li>
</ul>

<p>
Cette carte ne permet pas de payer en magasin ou encore de retirer de l’espèce dans un distributeur à billets. Mais elle permet de faire tous les paiements en ligne d’une carte classique.
</p><p>
Ce moyen de paiement virtuel est de plus en plus utilisé en entreprise avec des employées qui ont toujours besoin de faire des achats mais restent souvent derrière leur poste d’ordinateur.
</p>
<h4>
Prépayée, jamais de décovert</h4>
<p>
La prépayée est devenue la carte par défaut pour le compte standard des néobanques. Comme les néobanques débutent leur vie en tant que société de paiement et non en société de crédit comme une banque traditionnelle, elles n’autorisent pas les découverts. Vous devez donc avoir de l’argent sur votre compte bancaire avant de l’utiliser.
</p><p>
Avant, cette carte était souvent utilisées par les parents pour avoir un control sur les comptes jeunes, ou encore par les entreprises pour limiter les dépenses de leurs employés. La carte prépayé connaît aujourd’hui un renouveau avec ces néobanques qui associe des services supplémentaires à la carte comme les taux de changes à frais réduits.
</p><p>
Si vous cherchez la banque la moins chère vous n’avez pas besoin de crédit ou de découvert associé à votre compte, une carte prépayée est la manière la plus économique d’ouvrir un compte.
</p>
<h4>
Carte bancaire classique</h4>
<p>
C’est la carte bancaire que l’on connaît tous. La carte qui est associé aux comptes bancaires traditionnels mais aussi les banques en ligne et l’offre premium des néobanques.
</p><p>
On retrouve 2 marques de cartes bancaires classiques :
</p>
<ul>
  <li>Carte bancaire Visa</li>
  <li>Carte bancaire Mastercard</li>
  </ul>







</Post>

          </Container>
          <Footer/>
      </PageWrapper>
    )
}

export default Accounting
